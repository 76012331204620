import * as React from 'react'

import { useMutation, useQuery } from '@apollo/client'
import { Button, InputAdornment, MenuItem, Stack, Typography } from '@mui/material'
import { Field, Form, Formik } from 'formik'
import { CheckboxWithLabel, Select } from 'formik-mui'
import * as Yup from 'yup'

import {
  AssetBadge,
  ButtonContainer,
  Currency,
  ErrorDisplay,
  Loading,
  NotificationConfirmation,
} from 'shared/components'
import { CurrencyField } from 'shared/forms'
import {
  MARKET_ASSETS_QUERY,
  NOTIFY_USER_REQUEST_MUTATION,
  USER_OPERATIONS_QUERY,
} from 'shared/queries'
import {
  asOperationsArray,
  getTotalAmounts,
  isValidAssetAmount,
  setFormError,
  translateError,
} from 'shared/services'

import type { FormikProps } from 'formik'
import type {
  MarketAsset,
  MarketAssetsData,
  MarketAssetsVars,
  NotifyUserRequestData,
  NotifyUserRequestVars,
  UserOperationsData,
  UserOperationsVars,
} from 'shared/queries'

type FormValues = {
  symbol: string
  amount: number
  useMaxAmount: boolean
}

const initialValues: FormValues = {
  symbol: '',
  amount: 0,
  useMaxAmount: false,
}

type TotalAmounts = {
  [symbol: string]: number
}

const validationSchema = (totalAmounts: TotalAmounts): Yup.SchemaOf<FormValues> => (
  Yup.object().shape({
    symbol: Yup.string()
      .required('Este campo es obligatorio'),
    amount: Yup.number()
      .typeError('Debes ingresar un número')
      .required('Este campo es obligatorio')
      .positive('Debes ingresar un monto mayor a cero')
      .test(
        'validFormat',
        'Introduce un número con máximo 6 decimales',
        (value, context) => context.parent.useMaxAmount || isValidAssetAmount(value),
      )
      .test(
        'maxAmount',
        'Debes ingresar un monto menor o igual a tu balance',
        (value, context) => {
          if (!value) {
            return false
          }

          const symbol = context.parent.symbol

          if (!symbol) {
            return false
          }

          const totalAmount = totalAmounts[symbol]

          if (!totalAmount) {
            return false
          }

          return value <= totalAmount
        },
      ),
    useMaxAmount: Yup.boolean()
      .required('Este campo es obligatorio'),
  })
)

type InnerFormProps = FormikProps<FormValues> & {
  marketAssets: MarketAsset[]
  totalAmounts: TotalAmounts
}

const InnerForm = ({
  isSubmitting,
  isValid,
  status,
  setFieldTouched,
  setFieldValue,
  submitForm,
  values,
  marketAssets,
  totalAmounts,
}: InnerFormProps) => {
  const { symbol, useMaxAmount } = values

  const onSymbolChange = () => {
    setFieldValue('amount', 0)
    setFieldTouched('amount', false, false)
    setFieldValue('useMaxAmount', false)
    setFieldTouched('useMaxAmount', false, false)
  }

  const onUseMaxAmountChange = () => {
    if (!symbol) {
      return
    }

    const newUseMaxAmount = !useMaxAmount
    setFieldValue('useMaxAmount', newUseMaxAmount)
    setFieldValue('amount', newUseMaxAmount ? totalAmounts[symbol] : 0)
    setFieldTouched('amount', false, false)
  }

  return (
    <Form>
      <Stack
        spacing={2}
        marginTop={2}
      >
        <Field
          required
          name='symbol'
          type='text'
          label='Selecciona una criptomoneda'
          onChange={onSymbolChange}
          component={Select}
        >
          {marketAssets?.map((asset) => (
            <MenuItem
              key={asset.symbol}
              value={asset.symbol}
            >
              <AssetBadge
                symbol={asset.symbol}
                height={20}
                style={{ marginRight: 10 }}
              />
              {asset.name}
            </MenuItem>
          ))}
        </Field>
        <CurrencyField
          name='amount'
          label='Monto a retirar'
          disabled={!symbol || useMaxAmount}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <small>{symbol}</small>
              </InputAdornment>
            ),
          }}
          digits={6}
          positive
        />
        <Field
          required
          component={CheckboxWithLabel}
          disabled={!symbol}
          onChange={onUseMaxAmountChange}
          type='checkbox'
          name='useMaxAmount'
          Label={{
            sx: { mt: -1, mb: 1 },
            label: (
              <React.Fragment>
                Usar el máximo disponible (aprox.&nbsp;
                <Currency
                  currency={symbol}
                  value={totalAmounts[symbol]}
                />)
              </React.Fragment>
            ),
          }}
        />
        <ErrorDisplay
          errorMsg={status?.errorMsg}
          mt={2}
        />
        <ButtonContainer>
          <Button
            fullWidth
            disabled={isSubmitting || !isValid}
            onClick={submitForm}
            variant='contained'
          >
            Confirmar
          </Button>
        </ButtonContainer>
      </Stack>
    </Form>
  )
}

export const WithdrawBankForm = () => {
  const formRef = React.useRef<FormikProps<FormValues>>(null)
  const [showConfirmation, setShowConfirmation] = React.useState(false)
  const [message, setMessage] = React.useState<string[]>([])

  const { loading: assetsLoading, data: assetsData } =
    useQuery<MarketAssetsData, MarketAssetsVars>(MARKET_ASSETS_QUERY)

  const { loading: operationsLoading, data: operationsData } =
    useQuery<UserOperationsData, UserOperationsVars>(USER_OPERATIONS_QUERY)

  const symbols = assetsData?.marketAssets.map(({ symbol }) => symbol) || []
  const operations = asOperationsArray(operationsData?.userOperations)
  const totalAmounts = getTotalAmounts(symbols, operations)

  const [notifyUserRequest] =
    useMutation<NotifyUserRequestData, NotifyUserRequestVars>(
      NOTIFY_USER_REQUEST_MUTATION, {
        errorPolicy: 'all',
      })

  if (assetsLoading || operationsLoading) {
    return <Loading />
  }

  const handleSubmit = async (values: FormValues) => {
    const response = await notifyUserRequest({
      variables: {
        notificationType: 'WITHDRAW_BANK',
        content: [values.symbol, values.amount.toString()],
      },
    })

    if (response.data?.notifyUserRequest) {
      setMessage(response.data.notifyUserRequest)
      setShowConfirmation(true)
      return
    }

    setFormError(formRef, translateError(response))
  }

  return showConfirmation ? (
    <NotificationConfirmation message={message} />
  ) : (
    <React.Fragment>
      <Typography
        variant='h6'
        component='p'
        textAlign='center'
      >
        Formulario de retiro bancario
      </Typography>
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        validationSchema={validationSchema(totalAmounts)}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <InnerForm
            {...props}
            marketAssets={assetsData?.marketAssets || []}
            totalAmounts={totalAmounts}
          />
        )}
      </Formik>
    </React.Fragment>
  )
}
